.loginForm {
	max-width: 'auto';
	padding: 30px 40px 30px 40px;
	border-radius: 5px;
	font-family: "Ubuntu", sans-serif;
}

.formGroup {
	margin-bottom: 10px;
}

.label {
	display: block;
	margin-bottom: 5px;
	color: #141313;
	margin-top: 10px;
	font-size: 15px;
	margin-left: 10px;
}

.input {
	width: 100%;
	padding: 10px;
	font-size: 14px;
	border: 1px solid #b5b3b3;
	border-radius: 7px;
	background: white;
	transition: border-color 0.3s ease;
	color: black;
}

.input:hover {
    border-color: gray; /* Change the border color on hover */
}

.input:focus {
	border: 1px solid rgb(6, 179, 247); /* Change the /* Change the border color on focus */
}

.select {
	width: 300px;
	height: 45px;
	padding: 8px;
	font-size: 15px;
	border: 1px solid #b5b3b3;
	border-radius: 5px;
	background: white;
	transition: border-color 0.3s ease;
}

.select:focus {
	outline: none;
}

.button {
	width: 100%;
	background-color: #383535;
	color: white;
	padding: 8px 0px;
	border: none;
	border-radius: 7px;
	cursor: pointer;
	font-size: 16px;
}

.button:hover {
	background-color: #2e302e;
}
