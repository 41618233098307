.animate-plus-one {
    position: absolute;
    animation: moveUp 1s ease-out forwards;
    color: green;
    font-size: 20px;
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}
