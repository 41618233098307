/* PaymentForm.css */
.payment-container {
  margin: 20px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

h2 {
  margin-bottom: 15px;
  font-size: 15px;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

.payment-section,
.billing-section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.input-group2 {
  flex: 1 1 48%;
  display: flex;
  flex-direction: column;
}

.input-group2 label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.input-group2 input,
.input-group2 select {
  font-size: 14px;
  outline: none;
}

.input-group2 input:focus,
.input-group2 select:focus {
  border-color: #333;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancel-btn,
.continue-btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background: #f5f5f5;
  color: #333;
}

.continue-btn {
  background: #333;
  color: #fff;
}
