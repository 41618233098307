@media print {
    body {
      visibility: hidden;
    }
  #main{
    visibility: visible;
left: 0;
right: 0;
top: 0;
position: absolute;
  }
    #print-component,
    #print-component * {
      visibility: visible;
    }
    #print-component-qr,
    #print-component-qr * {
      visibility: visible;
    }
  
    #print-header,
    #print-header * {
      visibility: visible;


    }
  
    #print-header2 * {
      visibility: visible;
      height: 'auto';
    }
    #print-Fotter * {
      visibility: visible;
      height: 'auto';
    }
  
    /* #print-component {
      position: absolute;
      left: 0;
      top: 0;
    } */
  }
  
  @page {
    margin-bottom: 1cm; /* Adjust this value to set the desired bottom margin */
  }
  