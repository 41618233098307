.diagonalp-container {
  position: relative;
  /* width: 200px; 
    height: 200px;  */
  border: 1px dotted rgb(223, 218, 218); /* Add dotted border */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.diagonalp-1,
.diagonalp-2 {
  position: absolute;
  width: 300px;
  height: 1px;
  background-color: transparent; /* Make the background transparent */
  border-bottom: 1px dotted rgb(223, 218, 218); /* Add dotted line */
}

.diagonalp-1 {
  transform: rotate(-45deg);
  top: 50%;
}

.diagonalp-2 {
  transform: rotate(45deg);
  bottom: 50%;
}
