.scroll-container {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #e6e6e3 white; /* Thumb and track colors */
  }
  
  .scroll-container::-webkit-scrollbar {
    width: 5px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  